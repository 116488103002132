/* globals gtag:false */

import environments from "../../types/environments.js";
import { Logger } from "@fruugo/utilities";

const Log = new Logger(window.environment);

/**
 * @param {String} eventName
 * @param {String} eventCategory
 * @param {String} eventAction
 * @param {String} eventLabel
 */
export function sendGoogleAnalyticsEvent(
  eventName,
  event_category,
  event_action,
  event_label = window.currentCountry.toLowerCase()
) {
  gtag("event", eventName, { event_category, event_action, event_label });
}

/**
 * Create a payload for gtag enhanced ecommerce events
 * @param {{}} productData
 * @param {String} location The list or collection to which the product belongs (e.g. Search Results)
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#impression-data
 * @returns
 */
export function createEcommerceProductPayload(productData, location) {
  return productData.map(({ quantity, sku }, index) => {
    const payload = {
      id: sku.productId,
      name: sku.title,
      brand: sku.brand ? sku.brand.title : null,
      quantity,
      price: sku.price ? sku.price.eurValueForAnalytics : null,
      google_business_vertical: "retail",
    };
    if (location) {
      payload.list_name = location;
      payload.list_position = index;
    }
    return payload;
  });
}

/**
 * Log an Event for the Google Recommendation AI (RAI)
 * Global script in analytics.htm sets the required settings such as the API & Project keys
 * @param {{}} userEvent The event to track as specified: https://cloud.google.com/recommendations-ai/docs/record-events?hl=he#pixel
 * @see https://cloud.google.com/retail/docs/record-events
 * @returns
 */
export function logGoogleRecommendationsAIEvent(userEvent) {
  if (!userEvent) return;
  if (
    window.environment !== environments.production ||
    window.currentCountry !== "GB"
  )
    return;
  try {
    if (!window.GAKey)
      throw new Error("Error logging Google RAI: GA key not found.");
    // Get the visitor/clientId from GA
    gtag("get", window.GAKey, "client_id", (clientId) => {
      if (!clientId) return;
      userEvent.visitorId = clientId;
      sendDataToGoogleRecommendationAI(userEvent);
    });
  } catch (error) {
    Log.error(error);
  }
}

export function sendDataToGoogleRecommendationAI(userEvent) {
  if (!userEvent) return;
  if (!window.googleRAISettings)
    throw new Error("Error sending Google RAI data: settings not found.");
  var _gre = _gre || [];
  _gre.push(["apiKey", window.googleRAISettings.apiKey]);
  _gre.push(["projectId", window.googleRAISettings.projectId]);
  _gre.push(["locationId", window.googleRAISettings.locationId]);
  _gre.push(["catalogId", window.googleRAISettings.catalogId]);
  _gre.push(["logEvent", userEvent]);
  window._gre = _gre;

  var gre = document.createElement("script");
  gre.type = "text/javascript";
  gre.async = true;
  gre.src = "https://www.gstatic.com/retail/v2_event.js";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(gre, s);
}
