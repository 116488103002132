/*
     __  __            _        _         _
    |  \/  | __ _ _ __| | _____| |_ _ __ | | __ _  ___ ___
    | |\/| |/ _` | '__| |/ / _ \ __| '_ \| |/ _` |/ __/ _ \
    | |  | | (_| | |  |   <  __/ |_| |_) | | (_| | (_|  __/
    |_|  |_|\__,_|_|  |_|\_\___|\__| .__/|_|\__,_|\___\___|
                                   |_|
 */

import xhr from "./xhr.js";
import { i18n, waitForTranslations } from "./i18n.js";
import { sendGoogleAnalyticsEvent } from "../modules/analytics/google-analytics.js";

const API_KEY = "CkGqKAS2ICoJWAp6UzoRLscwa8qY6Ey4";
const LOCALE = calculateLocale();
const THRESHOLD = 3.8;

export function initTrustpilot() {
  const initialised =
    document.querySelectorAll(".trustpilot-widget").length > 0;
  if (initialised) return;

  getBusinessUnitIdForHostName()
    .then(getBusinessUnit)
    .then(checkTrustScore)
    .then(getAdditionalResources)
    .then(renderWidget)
    .catch(() => {
      // we take an optimistic approach and leave a gap on the page for the widget, then hide it when needed
      // this avoids layout changes in more cases than the alternative of hiding by default and showing on success
      hideContainers();
    });
}

function getBusinessUnitIdForHostName() {
  const hostname = getHostNameForCountryCode(window.currentCountry);

  if (hostname === undefined) {
    return Promise.reject(
      new Error(
        `invalid country for trustpilot widget: ${window.currentCountry}`
      )
    );
  }

  return cachedTrustPilotRequest(
    `https://api.trustpilot.com/v1/business-units/find?name=${hostname}`,
    "business-unit-find",
    (data) => data.id
  );
}

function getBusinessUnit(businessUnitId) {
  return cachedTrustPilotRequest(
    `https://api.trustpilot.com/v1/business-units/${businessUnitId}`,
    "business-unit"
  );
}

function checkTrustScore(businessUnit) {
  if (businessUnit.score.trustScore < THRESHOLD) {
    throw new Error("trustpilot score below display threshold");
  }
  return businessUnit;
}

function getAdditionalResources(businessUnit) {
  const webLink = businessUnit.links.find(
    (link) => link.rel === "business-units-web-links"
  );
  const { stars } = businessUnit.score;
  return Promise.all([
    Promise.resolve(businessUnit),
    cachedTrustPilotRequest(
      "https://api.trustpilot.com/v1/resources/images/logos",
      "images-logos"
    ),
    cachedTrustPilotRequest(`${webLink.href}?locale=${LOCALE}`, "weblink"),
    cachedTrustPilotRequest(
      `https://api.trustpilot.com/v1/resources/images/stars/${stars}`,
      "images-stars"
    ),
    waitForTranslations(),
  ]);
}

function renderWidget([businessUnit, images, weblink, starsImages]) {
  const {
    score: { stars, trustScore },
    numberOfReviews,
  } = businessUnit;

  const markup = Widget({
    url: weblink.profileUrl,
    ratingKey: stars === 4 ? "trustpilot.stars.4" : "trustpilot.stars.5",
    trustScore,
    reviews: new Intl.NumberFormat().format(numberOfReviews.total),
    starsSrc: starsImages.starSvg.url,
    logoSrc: images.lightBackground.logoSvg.url,
  });

  const targets = document.getElementsByClassName("js-trustpilot-summary");

  for (let i = 0; i < targets.length; i++) {
    const target = targets[i];
    target.innerHTML = markup;
    const inFooter = target.closest("footer") !== null;
    target.addEventListener("click", () => {
      const eventName = inFooter ? "footer-trustpilot" : "home-trustpilot";
      sendGoogleAnalyticsEvent(eventName, "view");
    });
  }
}

/**
 * template string based psuedo component
 */
const Widget = ({ url, ratingKey, starsSrc, trustScore, reviews, logoSrc }) =>
  `<a class="trustpilot-widget" href="${url}" target="_blank" rel="noopener">
    <span class="d-none d-lg-inline">${i18n("trustpilot.customers-say")}</span>
    <span class="d-none d-sm-inline rating">${i18n(ratingKey)}</span>
    <span><img src="${starsSrc}"></span>
    <span class="d-none d-md-inline">
        <strong>${trustScore}</strong>
        ${i18n("trustpilot.out-of")}
        <strong>${i18n("trustpilot.reviews", reviews)}</strong>
    </span>
    <img src="${logoSrc}" />
</a>`;

export function hideContainers() {
  const targets = document.getElementsByClassName("js-trustpilot-summary");
  for (let i = 0; i < targets.length; i++) {
    targets[i].classList.add("d-none");
  }
}

/**
 * Perform a trust pilot api call, but first try to get the cached value from session storage
 * key includes TP prefix, and also locale to avoid stale data on domain/language change
 */
function cachedTrustPilotRequest(url, key, selector = (data) => data) {
  const fullKey = `TP_${LOCALE}_${key}`;
  const cached = sessionStorage.getItem(fullKey);
  if (cached) return Promise.resolve(selector(JSON.parse(cached)));
  return xhr(url, "GET", { apiKey: API_KEY }).then((data) => {
    sessionStorage.setItem(fullKey, data.response);
    return selector(JSON.parse(data.response));
  });
}

/**
 * @param {String} countryCode
 */
function getHostNameForCountryCode(countryCode) {
  countryCode = countryCode.toLowerCase();

  // prettier-ignore
  const simpleDomains = ["ae","at","be","ca","ch","de","dk","es","fi","fr","ie","it","jp","nl","no","pl","pt","se","us"];
  const specialCaseDomains = {
    au: "fruugoaustralia.com",
    gb: "fruugo.co.uk",
    nz: "fruugo.co.nz",
    za: "fruugo.co.za",
  };

  if (specialCaseDomains[countryCode] !== undefined) {
    return specialCaseDomains[countryCode];
  }

  if (simpleDomains.includes(countryCode)) {
    return `fruugo.${countryCode}`;
  }
}

// trustpilot doesn't support all locales, so we need to hard code a couple of fallbacks
function calculateLocale() {
  const language = window.defaultLang;
  const country = window.currentCountry;

  const locale = `${language}-${country}`;

  if (locale === "no-NO") return "nb-NO";
  if (locale === "fr-CH") return "de-CH";

  return locale;
}
