/* globals gtag:false */
import { createModal, closeModal } from "./modal.js";
import { i18n, waitForTranslations } from "./i18n.js";
import {
  initTrustpilot,
  hideContainers as hideTrustPilotContainers,
} from "./trustpilot.js";
import { initBing, disableBingTracking } from "./bing.js";
import { initHotjar } from "./hotjar.js";
import xhr from "./xhr.js";
import { initClearpay } from "./clearpay.js";
import { enableFacebookPixel } from "./facebook-pixel.js";
import {
  getUserConsent,
  setUserConsent,
  defaultConsents,
} from "@fruugo/utilities";
import { initPaymentIcons } from "./payment-icons";

export const consentModalBlacklist = ["/help/detail/cookies"];

function consentOverridePresent() {
  const params = new URL(document.location).searchParams;
  return params.get("consent") !== null;
}

export function initCookieConsent() {
  document.querySelectorAll(".js-manage-cookies").forEach((link) =>
    link.addEventListener("click", (e) => {
      e.preventDefault();
      const consents = getUserConsent() || defaultConsents;
      showConsentModal(consents);
    })
  );

  if (consentModalBlacklist.includes(window.location.pathname)) {
    return;
  }

  // enable footer link
  document.querySelectorAll(".js-manage-cookies").forEach((link) =>
    link.addEventListener("click", (e) => {
      e.preventDefault();
      const consents = getUserConsent();
      showConsentModal(consents);
    })
  );

  // load persisted consent
  const consents = getUserConsent();
  if (consents) {
    enableFunctionality(consents);
  } else {
    if (consentOverridePresent()) {
      return;
    }
    showConsentModal(defaultConsents);
  }

  return;
}

// "routing"
let screen = "home";

function navigateToManage() {
  screen = "manage";
  const manageBtn = document
    .querySelector(".modal")
    .querySelector(".js-consent-btn-manage");
  const homeScreen = document
    .querySelector(".modal")
    .querySelector(".js-consent-screen-home");
  const manageScreen = document
    .querySelector(".modal")
    .querySelector(".js-consent-screen-manage");
  manageBtn.innerHTML = i18n("consent.actions.save");
  manageBtn.blur();
  homeScreen.classList.add("d-none");
  manageScreen.classList.remove("d-none");
}

function saveAndClose(modalOpenClass, consents) {
  screen = "home";
  updateConsents(consents);
  closeModal(modalOpenClass);
}

function showConsentModal(consents) {
  waitForTranslations().then(() => renderConsentModal(consents));
}

function renderConsentModal(consents) {
  // prettier-ignore
  const markup = `<div>
  <div class="js-consent-screen-manage d-none">
    <h2 class="text-center text-sm-left">${i18n("consent.manage.title")}</h2>
    <p>${i18n("consent.manage.text")}</p>
    <div>
      <div class="switch mb-16">
        <input type="checkbox" id="cookie-essential" checked disabled />
        <label for="cookie-essential">
          <span class="d-flex flex-column flex-sm-row align-items-sm-center">${i18n("consent.cookies.essential.title")} <small class="ml-0 ml-sm-16">${i18n("consent.required")}</small></span>
          <span></span>
        </label>
      </div>
      <div class="mb-32">
        <p>${i18n("consent.cookies.essential.description")}</p>
      </div>
    </div>
    <div>
      <div class="switch mb-16">
        <input type="checkbox" id="cookie-analytics" ${consents.analytics === true ? "checked" : ""} />
        <label for="cookie-analytics">
          <span>${i18n("consent.cookies.analytics.title")}</span>
          <span></span>
        </label>
      </div>
      <div class="mb-32">
        <p>${i18n("consent.cookies.analytics.description")}</p>
      </div>
    </div>
    <div>
      <div class="switch mb-16">
        <input type="checkbox" id="cookie-advertising" ${consents.advertising === true ? "checked" : ""} />
        <label for="cookie-advertising">
          <span>${i18n("consent.cookies.advertising.title")}</span>
          <span></span>
        </label>
      </div>
      <div class="mb-32">
        <p>${i18n("consent.cookies.advertising.description")}</p>
      </div>
    </div>
  </div>
  <div class="js-consent-screen-home">
    <h2 class="text-center text-sm-left">${i18n("consent.home.title")}</h2>
    <p class="mb-32">${i18n("consent.home.text")}</p>
  </div>
  <div class="modal-action-buttons d-flex flex-column flex-sm-row justify-content-end">
      <a href="#" class="btn btn-success js-consent-btn-accept">${i18n("consent.actions.accept")}</a>
      <a href="#" class="btn btn-dark js-consent-btn-manage">${i18n("consent.actions.manage")}</a>
      <a href="#" class="btn btn-outline-dark js-consent-btn-decline">${i18n("consent.actions.decline")}</a>
  </div>
</div>
`;

  const modalOpenClass = "modal-fade consent-modal";

  const modal = createModal(
    markup,
    null,
    modalOpenClass,
    "modal-dialog-centered modal-lg",
    "",
    false
  );

  const acceptBtn = document
    .querySelector(".modal")
    .querySelector(".js-consent-btn-accept");
  const declineBtn = document
    .querySelector(".modal")
    .querySelector(".js-consent-btn-decline");
  const manageBtn = document
    .querySelector(".modal")
    .querySelector(".js-consent-btn-manage");
  const analyticsInput = document
    .querySelector(".modal")
    .querySelector("#cookie-analytics");
  const advertisingInput = document
    .querySelector(".modal")
    .querySelector("#cookie-advertising");

  acceptBtn.addEventListener("click", (event) => {
    event.preventDefault();
    saveAndClose(modalOpenClass, {
      essential: true,
      analytics: true,
      advertising: true,
    });
  });

  declineBtn.addEventListener("click", (event) => {
    event.preventDefault();
    saveAndClose(modalOpenClass, {
      essential: true,
      analytics: false,
      advertising: false,
    });
  });

  manageBtn.addEventListener("click", (event) => {
    event.preventDefault();
    if (screen === "home") {
      navigateToManage(modal);
    } else {
      saveAndClose(modalOpenClass, {
        essential: true,
        analytics: analyticsInput.checked,
        advertising: advertisingInput.checked,
      });
    }
  });
}

function updateConsents(consents) {
  setUserConsent(consents);

  // ping the api to get/set back end cookies, mirror the current search string in case it includes referrer information
  xhr(`/marketplace/api/consent${window.location.search}`, "POST");

  enableFunctionality(consents);
}

function reloadAbTests() {
  // ABTEST CXO2-218_Oney
  initPaymentIcons(".js-payment-icons", true);
}

// everything in here needs to be idempotent as this can be called multiple times by the user
function enableFunctionality(consents) {
  gtag("consent", "update", {
    ad_storage: consents.advertising === true ? "granted" : "denied",
    ad_user_data: consents.advertising === true ? "granted" : "denied",
    ad_personalization: consents.advertising === true ? "granted" : "denied",
    analytics_storage: consents.analytics === true ? "granted" : "denied",
  });

  if (consents.analytics) {
    if (window.hotjarSiteId) {
      initHotjar();
    }

    reloadAbTests();
  }

  if (consents.advertising) {
    initTrustpilot();
    initBing();
    initClearpay();
    enableFacebookPixel();
  } else {
    hideTrustPilotContainers();
    disableBingTracking();
  }

  const consentUpdateEvent = new CustomEvent("consentUpdate", {
    detail: {
      consents,
    },
    bubbles: true,
    cancelable: true,
  });

  document.dispatchEvent(consentUpdateEvent);
}
