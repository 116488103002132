import { sendGoogleAnalyticsEvent } from "../modules/analytics/google-analytics.js";
import { stringToHtml } from "./utilities.js";

/**
 *
 * @description Gotcha: The clicked element to tigger the modal needs the .js-modal-open class, otherwise the modal will never show
 */
export function createModal(
  elementToContain = null,
  closeTitle = null,
  modalOpenClass = "modal-fade",
  dialogClass = "",
  bodyClass = "",
  allowClose = true
) {
  let modalClass = "";
  const container = "";
  const tabIndex = "-1";

  if (/modal-mobile-navigation/.test(modalOpenClass)) {
    modalClass = "modal-slide-in";
  }

  enforceSingleModal(modalOpenClass);

  const header = allowClose
    ? `<div class="modal-header">
    <span class="close js-modal-close d-flex">
        <svg class="icon mr-8"><use href="/marketplace/images/sprite-icon.svg#close"/></svg>
    </span>
    ${closeTitle || ""}
</div>`
    : "";

  const template = `<div class="modal modal-open ${modalClass} ${container} " tabindex="${tabIndex}" role="dialog" style="display: block;">
<div class="modal-dialog ${dialogClass}" role="document">
    <div class="modal-content">
        ${header}
        <div class="modal-body js-modal-body ${bodyClass}"></div>
    </div>
</div>
</div>`;

  const modal = stringToHtml(template);
  const backdrop = stringToHtml('<div class="modal-backdrop show"></div>');
  const content =
    elementToContain instanceof Element
      ? elementToContain
      : stringToHtml(elementToContain);
  content.classList.add("modal-active");
  modal.querySelector(".modal-body").appendChild(content);

  const mainContainer = dialogClass.includes("modal-dialog-centered")
    ? document.body
    : document.querySelector(".main-container");
  // measure the current scrollbar before opening the modal
  const scrollbarWidth = window.innerWidth - document.body.offsetWidth;

  document.body.classList.add("modal-open");
  mainContainer.classList.add(...modalOpenClass.split(" "), "modal-open");
  document.body.style.paddingRight = `${scrollbarWidth}px`;
  mainContainer.appendChild(backdrop);
  mainContainer.appendChild(modal);

  const modalContent = modal.querySelector(".modal-content");

  if (modalContent && allowClose) {
    modalContent.addEventListener(
      "click",
      function (event) {
        if (event.target.matches(".js-modal-close")) {
          event.preventDefault();
          closeModal(modalOpenClass);
        }
      },
      false
    );
    document.addEventListener(
      "click",
      (event) => {
        // Need to exclude the initial click element otherwise the modal never shows
        if (event.target.closest(".js-modal-open")) return;
        if (
          !event
            .composedPath()
            .includes(document.querySelector(".modal-content"))
        ) {
          closeModal(modalOpenClass);
        }

        event.stopImmediatePropagation();
      },
      false
    );
  }

  return modal;
}

export function closeModal(modalOpenClass) {
  const modal = document.querySelector(".modal");
  const modalBackdrop = document.querySelector(".modal-backdrop");
  const modalContainer = document.querySelector(".main-container");

  if (!modal || !modalBackdrop) return;

  modalContainer.classList.remove(...modalOpenClass.split(" "), "modal-open");
  document.body.classList.remove(
    ...modalOpenClass.split(" "),
    "modal-open",
    "modal-under-header"
  );
  document.body.style.paddingRight = null;
  modalBackdrop.remove();
  modal.remove();

  if (modalOpenClass.includes("modal-trustpilot--header")) {
    sendGoogleAnalyticsEvent("custom_event", "header-trustpilot", "hide");
  } else if (modalOpenClass.includes("modal-trustpilot--footer")) {
    sendGoogleAnalyticsEvent("custom_event", "footer-trustpilot", "hide");
  } else if (modalOpenClass.includes("modal-mobile-navigation")) {
    sendGoogleAnalyticsEvent("custom_event", "global-nav", "hide");
  }
}

let currentModalOpenClass = null;

export function enforceSingleModal(modalOpenClass) {
  if (currentModalOpenClass) {
    closeModal(currentModalOpenClass);
  }

  currentModalOpenClass = modalOpenClass;
}
