import { Logger, select } from "@fruugo/utilities";
import initReadMore from "./read-more.js";
import { toggleClass } from "./utilities";
import { i18n } from "./i18n";
var Log = new Logger(window.environment);
var skuTitleTranslationRequired = window.skuTitleTranslationRequired, skuTranslationRequired = window.skuTranslationRequired, skuId = window.skuId, productId = window.productId, currentLang = window.currentLang;
export function initProductTranslations() {
    if (skuTitleTranslationRequired) {
        translateProductTitle();
    }
    if (skuTranslationRequired) {
        var scrollTranslationRequired = true;
        if (scrollTranslationRequired) {
            translateProductVariants();
            translateProductDescription();
            scrollTranslationRequired = false;
        }
    }
    initViewOriginalDescription();
}
function translateProductDescription() {
    fetch("/marketplace/api/translation/skuDescriptionAndDisplayAttributes/".concat(skuId, "?language=").concat(currentLang))
        .then(function (res) { return res.json(); })
        .then(function (data) {
        var element = select(".js-product-description");
        if (element) {
            element.textContent = data.description;
        }
        // Re-init read more on the product description after adding in the translated version
        initReadMore();
    })
        .catch(function (error) {
        Log.error(error);
    });
}
function translateProductVariants() {
    fetch("/marketplace/api/translation/skuVariantAttributes/".concat(productId, "/").concat(skuId, "?language=").concat(currentLang))
        .then(function (res) { return res.json(); })
        .then(function (variantsData) {
        Object.keys(variantsData).forEach(function (key) {
            var attributeContainer = select(".form-group[data-attribute_id=\"+".concat(key, "+\"]"));
            Object.keys(variantsData[key].valueTranslationsMap).forEach(function (mapKey) {
                // Product Variant Options
                attributeContainer &&
                    attributeContainer
                        .querySelectorAll(".VisualSelect span")
                        .forEach(function (element) {
                        if (element.textContent === mapKey) {
                            element.textContent =
                                variantsData[key].valueTranslationsMap[mapKey];
                        }
                    });
            });
        });
    })
        .catch(function (error) {
        Log.error(error);
    });
}
function translateProductTitle() {
    fetch("/marketplace/api/translation/skuTitle/".concat(skuId, "?language=").concat(currentLang))
        .then(function (res) { return res.json(); })
        .then(function (data) {
        var _a, _b;
        var titleName = data.name.trim();
        var titleSeparatorIndex = document.title.lastIndexOf("|");
        if (titleSeparatorIndex > 0) {
            var productTitle = document.title
                .substring(0, titleSeparatorIndex)
                .trim();
            if (productTitle !== titleName) {
                document.title =
                    titleName + document.title.substring(titleSeparatorIndex - 1);
            }
        }
        (_a = select('meta[property="og:title"]')) === null || _a === void 0 ? void 0 : _a.setAttribute("content", titleName);
        (_b = select(".js-product-title")) === null || _b === void 0 ? void 0 : _b.setAttribute("title", titleName);
    })
        .catch(function (error) {
        Log.error(error);
    });
}
function initViewOriginalDescription() {
    var button = select(".js-view-original-description");
    var toggled = false;
    function handleViewOriginalDescription(event) {
        toggleClass(select(".js-product-description"), "d-none");
        toggleClass(select(".js-original-description"), "d-none");
        var button = event.target;
        button.textContent = toggled
            ? i18n("product.description.view-original")
            : i18n("product.description.view");
        toggled = !toggled;
    }
    button && button.addEventListener("click", handleViewOriginalDescription);
}
