import Glide, {
  Breakpoints,
  Controls,
  Swipe,
  Images,
  Anchors,
} from "@glidejs/glide/dist/glide.modular.esm";
import xhr from "./xhr.js";
import { createProductImpressionObserver } from "./analytics.js";
import { i18n } from "./i18n.js";
import { Logger, shuffleArray } from "@fruugo/utilities";
import { stringToHtml } from "../modules/utilities.js";

const Log = new Logger(window.environment);
const observer = createProductImpressionObserver();

export const initCarousels = (
  targets = document.getElementsByClassName("js-carousel")
) => {
  if (targets) {
    Array.from(targets).forEach((target) => {
      if (typeof target.dataset !== "undefined") {
        initCarousel(target)
          .then((carousel) =>
            getCarouselData(target).then((data) =>
              populateCarousel(target, carousel, data)
            )
          )
          .catch((error) => {
            target.remove();
            Log.error(error);
          });
      }
    });
  }
};

const initCarousel = (target) => {
  return new Promise((resolve) => {
    const carousel = new Glide(target, {
      type: "carousel",
      perView: 8,
      gap: 16,
      perSwipe: "|",
      direction: window.rtl ? "rtl" : "ltr",
      animationDuration: 800,
      peek: 16,
      breakpoints: {
        576: {
          perView: 2,
          peek: {
            before: 16,
            after: 50,
          },
          animationDuration: 200,
        },
        768: {
          perView: 3,
          peek: {
            before: 16,
            after: 50,
          },
          animationDuration: 300,
        },
        1280: {
          perView: 5,
          animationDuration: 800,
        },
        1600: {
          perView: 6,
        },
      },
    });
    resolve(carousel);
  });
};

const showCarouselArrows = (target) =>
  target
    .querySelectorAll(".glide__arrow")
    .forEach((arrow) => arrow.classList.add("d-md-block"));

const createProductTileMarkup = (data) => {
  const hasVoucher = window.voucher;
  const tile = stringToHtml(
    `<li class="glide__slide">
            <a href="${data.productPageLink.href}" class="carousel-item">
                <span class="carousel-item-image">
                    <img 
                      src="${
                        data.imageUrl
                          ? data.imageUrl
                          : "/marketplace/images/image-placeholder.png"
                      }" 
                      alt="${data.title}"
                      width="120"
                      height="120"
                      loading="lazy" 
                      onerror="handleImageError(this)">
                </span>
                <h5 class="carousel-item-title">${data.title}</h5>
                ${
                  hasVoucher
                    ? `<div class="carousel-item-discount">
                    <p class="mb-0">${i18n("product.recommendedPrice")} <del>${
                        data.normalPrice
                      }</del></p>
                    <p class="mb-0">${i18n("product.voucher.discount", [
                      data.priceDiscount,
                    ])}</p>
                    <p class="carousel-item-discount-price mb-0">${
                      data.price
                    }</p>
                  </div>`
                    : `<p class="carousel-item-price">${data.price}
                    ${
                      data.normalPrice
                        ? `<del class="carousel-item-price-original">${data.normalPrice}</del>`
                        : ""
                    }
                  </p>`
                }
            </a>
        </li>`
  );

  const properties = [
    "category",
    "brand",
    "title",
    "productId",
    "price",
    "retailerId",
    "retailerName",
  ];
  for (const prop of properties) {
    tile.dataset[prop] = data[prop];
  }

  return tile;
};

const populateCarousel = (target, carousel, data) => {
  const { productTiles } = data;
  const { title } = data;
  const carouselWrapper = target.querySelector(".glide__slides");
  const minTiles = 4;

  shuffleArray(productTiles);

  populateCarouselTitle(target, title);
  populateTiles(productTiles, target, carouselWrapper, createProductTileMarkup);

  if (productTiles && productTiles.length < minTiles) {
    carousel.settings.type = "slider";
  } else {
    showCarouselArrows(target);
  }

  carousel.mount({ Breakpoints, Controls, Swipe, Images, Anchors });
};

const populateCarouselTitle = (target, title) => {
  const placeholder = target.querySelector(".loading");
  if (placeholder) placeholder.remove();

  if (!title) return;

  const carouselTitle = document.createElement("h4");
  carouselTitle.innerHTML = title;
  carouselTitle.classList.add("carousel-heading");

  target.insertBefore(carouselTitle, target.firstChild);
};

const populateTiles = (tiles, target, carouselWrapper, createMarkup) => {
  if (tiles && tiles.length > 0) {
    carouselWrapper.innerHTML = "";
    tiles.forEach((tile) => {
      const markup = createMarkup(tile);
      carouselWrapper.appendChild(markup);
      observer.observe(markup);
    });
  } else {
    target.remove();
  }
};

const getCarouselData = (target) => {
  return new Promise((resolve, reject) => {
    const endpoint = target.dataset.endpoint;
    xhr(endpoint)
      .then((data) => JSON.parse(data.response))
      .then(resolve)
      .catch(reject);
  });
};
