import { getUserConsent, defaultConsents } from "@fruugo/utilities";
import { isMobileDevice } from "../utilities";
import { detect } from "detect-browser";

const pageType = window.pageType;
const browser = detect();

const defaultData = {
  eventName: pageType,
  platform: isMobileDevice() ? "mobile_web" : "desktop_web",
  browserName: browser.name,
  browserVersion: browser.version,
};

export const sendFruugoAnalyticsPageViewEvent = (item) => {
  const url = `/event/log/page-view`;
  const data = buildItemEventData(item);

  sendFruugoAnalyticsEvent(url, data);
};

export const sendFruugoAnalyticsClickEvent = (item) => {
  const url = `/event/log/click`;
  const data = buildItemEventData(item);

  sendFruugoAnalyticsEvent(url, data);
};

export const sendFruugoAnalyticsImpressionEvent = (items) => {
  const url = `/event/log/impression`;
  const data = {
    ...defaultData,
    items: items.map((item) => buildItemEventData(item)),
  };

  sendFruugoAnalyticsEvent(url, data);
};

export const sendFruugoAnalyticsAddToCartEvent = (productId) => {
  const url = `/event/log/add-to-cart`;
  const data = {
    ...defaultData,
    productId,
  };

  sendFruugoAnalyticsEvent(url, data);
};

export const sendFruugoAnalyticsBuyNowEvent = (productId) => {
  const url = `/event/log/buy-now`;
  const data = {
    ...defaultData,
    productId,
  };

  sendFruugoAnalyticsEvent(url, data);
};

export const sendFruugoAnalyticsRemoveItemEvent = (productId) => {
  const url = `/event/log/remove-item`;
  const data = {
    ...defaultData,
    productId,
  };

  sendFruugoAnalyticsEvent(url, data);
};

export const sendFruugoAnalyticsSearchEvent = (query) => {
  const url = `/event/log/search`;
  const data = {
    ...defaultData,
    searchQuery: encodeURIComponent(query),
  };

  sendFruugoAnalyticsEvent(url, data);
};

const sendFruugoAnalyticsEvent = (url, data) => {
  const consents = getUserConsent() || defaultConsents;

  if (consents.analytics) {
    navigator.sendBeacon(url, JSON.stringify(data));
  }
};

const getParentProductId = () =>
  pageType && pageType == "product" ? window.skuInfo.productId : "";

const getCarouselName = (listName) => {
  if (!listName) return pageType;
  if (listName.includes("search")) return pageType;

  const parts = listName.split(".");

  if (parts.length <= 1) return pageType;

  return `${pageType}_carousel_${parts[1]}`;
};

const getCarouselPosition = (listName) => {
  const parts = listName.split(".");

  if (!listName || parts.length < 2) return null;

  return parts[1];
};

const buildItemEventData = (item) => {
  const carouselName = getCarouselName(item.list_name);
  const carouselPosition = getCarouselPosition(item.list_name);

  const data = {
    ...defaultData,
    productId: item.id,
    eventName: carouselName ?? pageType,
  };

  const parentProductId = getParentProductId();
  if (parentProductId) data.parentProductId = parentProductId;

  if (carouselPosition) data.carouselPosition = carouselPosition;

  return data;
};
